import React from 'react';
import {graphql, StaticQuery} from 'gatsby';
import {Helmet} from 'react-helmet';
import PropTypes from 'prop-types';

import favicon16 from '../media/favicons/favicon-16x16.png';
import favicon32 from '../media/favicons/favicon-32x32.png';
import favicon96 from '../media/favicons/favicon-96x96.png';
import icon120 from '../media/favicons/icon-120.png';
import icon152 from '../media/favicons/icon-152.png';
import icon167 from '../media/favicons/icon-167.png';
import icon180 from '../media/favicons/icon-180.png';
import maskIcon from '../media/favicons/icon-mask.svg';

export default class Meta extends React.Component {
    static propTypes = {
        article: PropTypes.bool,
        description: PropTypes.string,
        image: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string,
    };

    handleTitle(pageTitle, siteTitle) {
        return `${pageTitle} - ${siteTitle}`;
    }

    render() {
        return (
            <StaticQuery
                query={graphql`
                    {
                        site {
                            siteMetadata {
                                description
                                title
                                url
                            }
                        }
                    }
                `}
                render={(data) => (
                    <Helmet
                        htmlAttributes={{lang: 'en'}}
                        title={
                            this.props.title
                                ? this.handleTitle(
                                      this.props.title,
                                      data.site.siteMetadata.title,
                                  )
                                : data.site.siteMetadata.title
                        }
                    >
                        <meta
                            content={
                                this.props.description
                                    ? this.props.description
                                    : data.site.siteMetadata.description
                            }
                            name="description"
                        />
                        {this.props.url && (
                            <meta content={this.props.url} property="og:url" />
                        )}
                        {this.props.article && (
                            <meta content="article" property="og:type" />
                        )}
                        {this.props.title && (
                            <meta
                                content={this.handleTitle(
                                    this.props.title,
                                    data.site.siteMetadata.title,
                                )}
                                property="og:title"
                            />
                        )}
                        {this.props.description && (
                            <meta
                                content={this.props.description}
                                property="og:description"
                            />
                        )}
                        {this.props.title && (
                            <meta
                                content={this.handleTitle(
                                    this.props.title,
                                    data.site.siteMetadata.title,
                                )}
                                name="twitter:title"
                            />
                        )}
                        {this.props.description && (
                            <meta
                                content={this.props.description}
                                name="twitter:description"
                            />
                        )}
                        {this.props.image && (
                            <meta content={this.props.image} name="image" />
                        )}
                        {this.props.image && (
                            <meta
                                content={this.props.image}
                                property="og:image"
                            />
                        )}
                        {this.props.image && (
                            <meta content="1200" property="og:image:width" />
                        )}
                        {this.props.image && (
                            <meta
                                content={this.props.image}
                                name="twitter:image"
                            />
                        )}
                        {this.props.image && (
                            <meta
                                content="summary_large_image"
                                name="twitter:card"
                            />
                        )}
                        {this.props.image && (
                            <meta
                                content={this.props.image}
                                name="twitter:image"
                            />
                        )}
                        <link
                            href={favicon16}
                            rel="icon"
                            sizes="16x16"
                            type="image/png"
                        />
                        <link
                            href={favicon32}
                            rel="icon"
                            sizes="32x32"
                            type="image/png"
                        />
                        <link
                            href={favicon96}
                            rel="icon"
                            sizes="96x96"
                            type="image/png"
                        />
                        <link
                            href={icon120}
                            rel="apple-touch-icon"
                            sizes="120x120"
                            type="image/png"
                        />
                        <link
                            href={icon152}
                            rel="apple-touch-icon"
                            sizes="152x152"
                            type="image/png"
                        />
                        <link
                            href={icon167}
                            rel="apple-touch-icon"
                            sizes="167x167"
                            type="image/png"
                        />
                        <link
                            href={icon180}
                            rel="apple-touch-icon"
                            sizes="180x180"
                            type="image/png"
                        />
                        <link
                            color="#00AEC7"
                            href={maskIcon}
                            rel="mask-icon"
                            sizes="any"
                        />
                    </Helmet>
                )}
            />
        );
    }
}
